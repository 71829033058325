<template>
  <div>
    <SiteHeader></SiteHeader>
    <div class="topbar text-center">
      <strong class="fs-1"
        >{{ $t("Be Our Business Partner or") }}
        <a
          class="loginbtn text-primary text-decoration-underline"
          @click="tologin"
          >{{ $t("Login Now") }}</a
        >
      </strong>
    </div>

    <div class="container mt-5 mb-5">
      <div class="jumbotron psrel mb-4">
        <h4 class="fs-4">{{ $t("Register") }}</h4>
        <hr class="my-4" />
      </div>

      <div class="register mb-3">
        <el-form ref="form" label-position="top" :model="form">
          <el-row :gutter="20">
            <el-col :span="12" class="info-form">
              <el-form-item label="What are you interested in?" prop="item">
                <el-select
                  v-model="form.item"
                  placeholder="Please select the brand."
                  clearable
                >
                  <el-option
                    label="HotWheels: Premium Series"
                    value="HotWheels: Premium Series"
                  ></el-option>
                  <el-option
                    label="HotWheels: Advanced Series"
                    value="HotWheels: Advanced Series"
                  ></el-option>
                  <el-option
                    label="HotWheels: Basic Series"
                    value="HotWheels: Basic Series"
                  ></el-option>
                  <el-option label="MatchBox" value="MatchBox"></el-option>
                  <el-option
                    label="JurassicWorld"
                    value="JurassicWorld"
                  ></el-option>
                  <el-option label="Barbie" value="Barbie"></el-option>
                  <el-option
                    label="Macnn Exclusive"
                    value="Macnn Exclusive"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="What items are you looking to purchase?"
                prop="desc"
              >
                <el-input
                  type="textarea"
                  v-model="form.desc"
                  clearable
                  placeholder="You can fill in the item number, e.g. GJT68/FPY86; you can also fill in the name of the product, e.g. CarCulture/TeamTransport."
                ></el-input>
              </el-form-item>
              <el-form-item
                label="What's the minimum order quantity you can order?"
                prop="num"
              >
                <el-tooltip
                  effect="dark"
                  content="Note: the unit is box."
                  placement="bottom"
                >
                  <el-input-number
                    v-model="form.num"
                    :min="30"
                    :max="10000"
                    label="MOQ"
                  ></el-input-number>
                </el-tooltip>
              </el-form-item>
              <el-form-item
                label="Do you have experience in international sourcing?"
                prop="experience"
              >
                <el-switch
                  v-model="form.experience"
                  active-text="YES"
                  inactive-text="NO"
                  active-value="1"
                  inactive-value="0"
                ></el-switch>
              </el-form-item>
              <el-form-item
                label="Do you have a freight forwarder to take care of the transportation aspect?"
                prop="freight"
              >
                <el-switch
                  v-model="form.freight"
                  active-text="YES"
                  inactive-text="NO"
                  active-value="1"
                  inactive-value="0"
                ></el-switch>
              </el-form-item>
              <el-form-item
                label="How would you like to pay for the goods?"
                prop="payment"
              >
                <el-checkbox-group v-model="form.payment">
                  <el-checkbox label="PayPal Online" name="type"></el-checkbox>
                  <el-checkbox
                    label="Telegraphic Transfer (TT)"
                    name="type"
                  ></el-checkbox>
                  <el-checkbox label="Other" name="type"></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="$t('user')" prop="username">
                <el-tooltip
                  effect="dark"
                  :content="$t('usertip')"
                  placement="bottom"
                >
                  <el-input
                    placeholder="Please enter a valid e-mail address"
                    v-model="form.username"
                    :disabled="countdown > 0"
                    name="username"
                    clearable
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-refresh"
                      @click="sendVerifyCode"
                      >{{
                        countdown === 0 ? $t("getcode") : `Resend(${countdown})`
                      }}</el-button
                    >
                  </el-input>
                </el-tooltip>
              </el-form-item>

              <el-form-item :label="$t('Captcha')" prop="verify_code">
                <el-tooltip
                  effect="dark"
                  :content="$t('CaptchaTip')"
                  placement="bottom"
                >
                  <el-input
                    class="ajax_captcha"
                    v-model="form.verify_code"
                    name="captcha"
                    ref="captcha"
                    clearable
                  ></el-input>
                </el-tooltip>
              </el-form-item>

              <el-form-item :label="$t('Password')" prop="password">
                <el-tooltip
                  effect="dark"
                  :content="$t('PasswordTip')"
                  placement="bottom"
                >
                  <el-input
                    placeholder="Please enter your password"
                    v-model="form.password"
                    name="password"
                    ref="password"
                    show-password
                    clearable
                  ></el-input>
                </el-tooltip>
              </el-form-item>

              <el-form-item :label="$t('ConfirmPassword')" prop="password2">
                <el-tooltip
                  effect="dark"
                  :content="$t('ConfirmPasswordTip')"
                  placement="bottom"
                >
                  <el-input
                    placeholder="Please enter your password"
                    v-model="form.password2"
                    name="password2"
                    ref="password2"
                    show-password
                    clearable
                  ></el-input>
                </el-tooltip>
              </el-form-item>

              <el-form-item :label="$t('ClientType')" prop="clientType">
                <el-select
                  aria-label="Default select"
                  v-model="form.clientType"
                  placeholder="Please select the type of client."
                >
                  <el-option :label="$t('ClientType1')" value="2"></el-option>
                  <el-option :label="$t('ClientType2')" value="3"></el-option>
                  <el-option :label="$t('ClientType3')" value="4"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item>
                <el-checkbox v-model="form.allowNote">{{
                  $t("Allow notifications to be accepted")
                }}</el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="pt-0">
              <el-form-item class="text-end mb-0">
                <el-button type="primary" @click="toNote">
                  {{ $t("Submit") }}</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <!-- 新增对话框组件 -->
      <el-dialog
        title="Confirm Registration"
        :visible.sync="dialogVisible"
        width="30%"
        center
      >
        <span
          >Thank you very much for your application, we will review it within 48
          hours. After review, you will receive another email from us. Until
          then, you will not be able to log in to view specific information
          about the item.</span
        >
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="toRegister">Register</el-button>
        </span>
      </el-dialog>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import SiteHeader from "@/components/website/SiteHeader.vue";
import Foot from "@/components/website/Foot.vue";
import { register, sendVerifyCode } from "@/api/login.js";

export default {
  name: "Register",
  components: {
    SiteHeader,
    Foot,
  },
  data() {
    return {
      countdown: 0,
      form: {
        clientType: "2",
        allowNote: false,
        username: "", // 确保用户名已经初始化
        password: "",
        password2: "",
        verify_code: "",
        item: "",
        desc: "",
        num: 0,
        experience: true,
        freight: true,
        payment: [],
      },
      dialogVisible: false, // 新增对话框可见性状态
    };
  },
  methods: {
    startCountdown() {
      if (this.countdown !== 0) {
        return; // 如果已经开始了倒计时则不再进行操作
      }
      this.countdown = 60; // 设置倒计时为60秒
      const timer = setInterval(() => {
        this.countdown--; // 每次间隔1秒将倒计时数量减少1
        if (this.countdown <= 0) {
          clearInterval(timer); // 当倒计时结束后清除定时器
        }
      }, 1000);
    },
    sendVerifyCode() {
      // 验证用户名是否为空
      if (!this.form.username) {
        this.$message({
          message: this.$t("Please enter a valid e-mail address."),
          type: "warning",
        });
        return;
      }
      // 执行发送验证码逻辑
      this.startCountdown();
      sendVerifyCode({ username: this.form.username, type: 1 })
        .then((res) => {
          this.$message({
            message: res.message,
            type: "success",
          });
        })
        .catch((error) => {
          console.error("Error sending verification code:", error);
          this.$message.error(this.$t("error-sending-verification-code"));
        });
    },

    // 新增方法用于处理注册按钮点击事件
    toNote() {
      // 判断是否有未填写的字段，排除 form.freight
      const emptyFields = Object.keys(this.form).filter(
        (key) => key !== "freight" && key !== "experience" && !this.form[key]
      );
      if (emptyFields.length > 0) {
        this.$message.error("Please fill in all fields.");
        return;
      }
      this.dialogVisible = true;
    },

    toRegister() {
      // 构建 reginfo 对象
      const info = {
        item: this.form.item,
        desc: this.form.desc,
        num: this.form.num,
        experience: this.form.experience,
        freight: this.form.freight,
        payment: this.form.payment,
      };

      const countryName = localStorage.getItem("country_name");
      //console.log(countryName);

      // 执行注册逻辑
      const params = {
        clienType: this.form.clientType,
        username: this.form.username,
        verify_code: this.form.verify_code,
        password: this.form.password,
        country: countryName,
        info: JSON.stringify(info), // 将 reginfo 对象转换为 JSON 字符串
      };
      register(params)
        .then((res) => {
          if (res.code === 1) {
            this.$message({
              message: "Registration Successful!",
              type: "success",
            });
            // 注册成功后跳转到首页
            this.$router.push("/");
          } else {
            this.$message.error(res.message || "Registration failed!");
          }
        })
        .catch((error) => {
          console.error("Registration error:", error);
          this.$message.error("Registration failed!");
        });
    },
    tologin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.register {
  form {
    padding: 10px;
  }
  .info-form {
    background-color: var(--el-color-primary-light-9);
  }
  .el-col {
    padding-top: 10px;
    .el-select {
      width: 100%;
    }
  }
}
</style>
<style>
.el-form--label-top .el-form-item__label {
  margin-bottom: 5px;
  padding-bottom: 0px;
  line-height: 32px;
}
</style>
